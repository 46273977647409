import { Routes } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const CommonLayout_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("../../add-accounts/add-accounts.module").then(
        (m) => m.AddAccountsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    loadChildren: () =>
      import("../../reports/reports.module").then(
        (m) => m.ReportsModule 
      ),
    canActivate: [AuthGuard],
  },
];
