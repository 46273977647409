import { Component } from "@angular/core";
import { ROUTES } from "./side-nav-routes.config";
import { ThemeConstantService } from "../../services/theme-constant.service";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./side-nav.component.html",
})
export class SideNavComponent {
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  constructor(
    private themeService: ThemeConstantService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }

  userHasAccess(usertypes: number[]) {
    let currentUser = this.auth.getCurrentUserType();

    if (usertypes.includes(currentUser)) return true;
    else return false;
  }
}
