import { SideNavInterface } from "../../interfaces/side-nav.type";
export const ROUTES: SideNavInterface[] = [
  {
    path: "dashboard",
    title: "Dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    userAcess: [1, 2, 4],
    submenu: [],
  },
  {
    path: "/accounts",
    title: "Accounts",
    iconType: "nzIcon",
    iconTheme: "outline",
    userAcess: [1, 2, 3, 4],
    icon: "dollar",
    submenu: [],
  },

  {
    path: "/restaurants",
    title: "Restaurants",
    iconType: "nzIcon",
    iconTheme: "outline",
    userAcess: [1, 4],
    icon: "shop",
    submenu: [],
  },
  {
    path: "/users",
    title: "Users",
    iconType: "nzIcon",
    iconTheme: "outline",
    userAcess: [1, 4],
    icon: "user",
    submenu: [],
  },
  {
    path: "/reports",
    title: "Reports",
    iconType: "nzIcon",
    iconTheme: "outline",
    userAcess: [1, 2, 4],
    icon: "line-chart",
    submenu: [],
  },
];
