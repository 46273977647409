import { Component, OnDestroy, OnInit } from "@angular/core";
import { ThemeConstantService } from "../../services/theme-constant.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  user;
  userRole;
  userSub: Subscription | undefined;
  constructor(
    private themeService: ThemeConstantService,
    private router: Router,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );

    this.user = this.auth.currentUserValue;

    if (this.user.user_role_id === 1) this.userRole = "Admin";
    if (this.user.user_role_id === 2) this.userRole = "Accountant";
    if (this.user.user_role_id === 3) this.userRole = "Cashier";
    if (this.user.user_role_id === 4) this.userRole = "Management";
  }
  ngOnDestroy(): void {
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }

  notificationList = [
    {
      title: "You received a new message",
      time: "8 min",
      icon: "mail",
      color: "ant-avatar-" + "blue",
    },
    {
      title: "New user registered",
      time: "7 hours",
      icon: "user-add",
      color: "ant-avatar-" + "cyan",
    },
    {
      title: "System Alert",
      time: "8 hours",
      icon: "warning",
      color: "ant-avatar-" + "red",
    },
    {
      title: "You have a new update",
      time: "2 days",
      icon: "sync",
      color: "ant-avatar-" + "gold",
    },
  ];

  logout() {
    localStorage.clear();
    this.router.navigateByUrl("/authentication");
  }
}
