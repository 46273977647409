import { BrowserModule } from "@angular/platform-browser";
import { DEFAULT_CURRENCY_CODE, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import en from "@angular/common/locales/en";

import { AppRoutingModule } from "./app-routing.module";
import { TemplateModule } from "./shared/template/template.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";
import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";

import { NgChartjsModule } from "ng-chartjs";
import { ThemeConstantService } from "./shared/services/theme-constant.service";

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, CommonLayoutComponent, FullLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "SAR " },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    ThemeConstantService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
